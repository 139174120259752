/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'next': {
    width: 17,
    height: 17,
    viewBox: '0 0 17 17',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.081 5.464L12.65 7.07l-11.182.098a.698.698 0 00-.694.717.724.724 0 00.728.705l11.182-.098-1.491 1.633a.73.73 0 00.024.995.67.67 0 00.98-.01l2.604-2.85a.73.73 0 00-.024-.994L12.04 4.46a.67.67 0 00-.982.009.73.73 0 00.024.994z" _fill="#000"/>'
  }
})
